import React from 'react';
import ReactDOM from 'react-dom/client';
import Course from './Course';
import {ChakraProvider} from "@chakra-ui/react";
import {theme} from "./Theme";
import "./index.css"

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <ChakraProvider theme={theme}>
        <Course/>
    </ChakraProvider>
);
