import {createMultiStyleConfigHelpers, extendTheme} from "@chakra-ui/react"
import {selectAnatomy} from "@chakra-ui/anatomy";
import {green} from "./components/colors";

const {
    definePartsStyle,
    defineMultiStyleConfig
} = createMultiStyleConfigHelpers(selectAnatomy.keys);

/*const outline = definePartsStyle({
    field: {
        fontFamily: "Raleway-light",
        fontSize: "20px",
        borderRadius: 0,
        textColor: "white",
        borderColor: "#b7db00cc",
        backgroundColor: "#434343",

        _focusWithin: {
            ringColor: "#b7db00cc",
            ring: "2px",
            ringOffset: "1px",
            ringOffsetColor: "#b7db00cc",
            borderColor: "#b7db00cc"
        }
    }
});*/

const outline = definePartsStyle({
    field: {
        fontFamily: "Raleway-light",
        fontSize: "20px",
        borderRadius: 0,
        textColor: "white",
        borderColor: green,
        backgroundColor: "#434343",

        _focusWithin: {
            ringColor: green,
            ring: "2px",
            ringOffset: "1px",
            ringOffsetColor: green,
            borderColor: green
        }
    }
});

export const selectTheme = defineMultiStyleConfig({
    variants: {outline},
});

export const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "rgba(25, 26, 20, 1)"
            }
        }
    },
    components: {
        Select: selectTheme,
    }
});